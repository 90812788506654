import { render, staticRenderFns } from "./addCompany.vue?vue&type=template&id=002ffb8d&scoped=true&"
import script from "./addCompany.vue?vue&type=script&lang=js&"
export * from "./addCompany.vue?vue&type=script&lang=js&"
import style0 from "./addCompany.vue?vue&type=style&index=0&id=002ffb8d&prod&lang=scss&scoped=true&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "002ffb8d",
  null
  
)

export default component.exports